<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="no-gutters auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="6"
        class="
          d-flex
          flex-column
          justify-content-between
          align-items-center
          auth-bg
          px-5
          p-lg-5
        "
      >
      
        <b-col  class="d-flex align-items-center flex-1">
          <b-col sm="8" md="9" lg="9" class="px-xl-2 mx-auto flex-1">
            <div class="py-5 my-1">
              <b-link class="">
                <wameed-logo />
              </b-link>
            </div>
            <div class="py-5">
              <div class="pb-5 my-5">
                <b-card-title class="pb-1 mb-0 text-bold-22 text-font-main">
                 {{$t('login.title')}}
                  
                </b-card-title>
                <b-card-text
                  class="mb-2 mt-3 text-medium-16 text-font-secondary"
                >
                 {{$t('login.login_subtitle')}}
                </b-card-text>
              </div>
              <wameed-form refs="" @submitAction="login">
                <!-- form -->
                <template slot="form-content" slot-scope="{ invalid }">
                  <!-- email -->
                  <div class="pt-1">
                    <text-input
                      id="login-email"
                      v-model="email"
                      :label="$t('login.email')"
                      inputClasses="text-book-18 "
                      fieldClasses="pb-1 login-inputs"
                      name="email"
                      rules="required|email"
                      placeholder="john@example.com"
                    />

                    <!-- forgot password -->
                    <text-input
                      id="login-password"
                      v-model="password"
                      :label="$t('login.password')" 
                      fieldClasses="pb-1 login-inputs"
                      inputClasses="text-book-18"
                      name="password"
                      rules="required"
                      :icon="passwordToggleIcon"
                      :type="passwordType"
                      placeholder="john@example.com"
                      @iconClick="
                        passwordType == 'password'
                          ? (passwordType = 'text')
                          : (passwordType = 'password')
                      "
                    />
                    <!-- checkbox -->
                    <wameed-switch
                      v-model="rememberMe"
                      name="check-button"
                      @submitAction="rememberMe = !rememberMe"
                      :title="$t('login.remember_me')"
                      :checked="rememberMe"
                      classes="text-book-16 text-font-sub   pb-5"
                    />

                    <!-- submit buttons -->
                    <wameed-btn
                      :disabled="invalid"
                      :title="$t('login.login')"
                      type="submit"
                      variant="main"
                      block
                      :classes="'login-btn text-medium-19 text-white rounded-8'"
                    />
                  </div>
                </template>
              </wameed-form>
            </div>
          </b-col>
        </b-col>
        <div class="d-flex align-items-center flex-0 py-5">
          <span class="text-book-16 text-font-secondary my-5"
            >{{$t('all_rights')}}</span
          >
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 login-bg">
        <div
          class="
            w-100
            d-flex
            align-items-center
            justify-content-center
            px-5
            object-fit
          "
        >
          <!-- <img   :src="require('@/assets/images/pages/login-bg.svg')"/> -->
          <component
            style="max-height: 516.468px"
            v-bind:is="imgUrl"
          ></component>
          <!-- <b-img fluid :src="imgUrl" alt="Login V2" /> -->
        </div>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import WameedLogo from "@core/layouts/components/Logo.vue";

import store from "@/store/index";
import { mapActions } from "vuex";

import TextInput from "@/components/TextInput.vue";
import WameedForm from "@/components/WameedForm.vue";
import WameedBtn from "@/components/WameedBtn.vue";
import WameedSwitch from "@/components/WameedSwitch.vue";
// import TextInput from '@/components/TextInput.vue'

export default {
  // directives: {
  //   'b-tooltip': VBTooltip,
  // },
  components: {
    WameedLogo,
    TextInput,
    WameedForm,
    WameedBtn,
    WameedSwitch,
  },
  data() {
    return {
      status: "",
      password: "",
      email: "",
      sideImg: "login-bg",
      rememberMe: false,
      passwordType: "password",

      // validation rules
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordType === "password" ? "eyeon-icon" : "eyeoff-icon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return "login-bg-dark";
      }
      return "login-bg";
    },
  },
  methods: {
        ...mapActions({ submitLogin: "submitLogin" }),
    login() {
      // this.$refs.loginForm.validate().then((success) => {
      //   if (success) {
          this.submitLogin({
        email: this.email,
        password: this.password, 
        remember_me: this.rememberMe,

      // });
      //   }
      });
    },
  },
};
</script>

